<template>
  <div class="app-container">
    <folder-view :title="title" :encryptionStr="encryptionStr" :type="type" :hasBtn="true" :hasSelect="true"></folder-view>
  </div>
</template>
<script>
import folderView from '@/components/folderView/index.vue'

export default {
  components: { folderView },
  data() {
    return {
      encryptionStr: '-1',
      title: '我的空间',
      type: 'my'
    }
  }
  // mounted() {
  //   this.chapterId = this.$route.params.pathMatch
  // }
}
</script>
